import { Device } from "@capacitor/device";
import { auth } from "../../../firebaseConfig";
import { SpeechMark } from "../../audiobooks/store/reducers";
import { getTTS } from "../../popover/common/ttsServices";
import { argumentServices } from "../../sessions/store/services";
import { settingsServices, UserSettings } from "../../settings/store/services";
import { summaryServices } from "../store/services";
import { SummaryViewer } from "./SummaryViewer";
import { StatusBar } from "@capacitor/status-bar";

// interface SummaryViewer {
// 	pages: Page[];
// }
// interface Block {
// 	id: string;
// 	content: string;
// 	type: string;
// 	index: number;
// 	style: {
// 		bold: boolean;
// 		italic: boolean;
// 	};
// }
interface NodeToRemove {
	oldNode: Node;
	newNode: Node | null;
}

// interface Paragraph {
// 	id: string;
// 	blocks: Block[];
// }

// interface Page {
// 	id: string;
// 	paragraphs: Paragraph[];
// }
interface TTSChunk {
	text: string;
	speechMarks: any[];
	audioSrc: string;
}

interface StoredTTSData {
	allSpeechMarks: any[];
	allAudioSrc: string[];
	processedChunkIndex: number;
}

type GlobalStateType = {
	tokenBearer: string;
	ttsSettings: UserSettings | null;
	hasProcessedChunk: boolean;
	playingNextChunk: boolean;
	playingWordsIndex: number;
	currentChunkIndexGlobal: number;
	chunkProcessingIndex: number;
	currentChunkIndexPlaying: number;
	allSpeechMarks: any[];
	allAudioSrc: string[];
	prevSpeechMarkLength: number;
	chunks: [number, string[]][];
	processedChunks: TTSChunk[];
	backwardsAudio: boolean;
	forwardBackwardsChunkIndex: number;
	forwardBackwardsWordIndex: number;
	customStart: boolean;
	karaokeApplied: boolean;
	stopKaraoke: null;
	ttsCreating: boolean;
};

const initialState: GlobalStateType = {
	tokenBearer: "",
	ttsSettings: null,
	hasProcessedChunk: false,
	playingNextChunk: false,
	playingWordsIndex: 0,
	currentChunkIndexGlobal: 0,
	chunkProcessingIndex: 0,
	currentChunkIndexPlaying: 0,
	allSpeechMarks: [],
	allAudioSrc: [],
	prevSpeechMarkLength: 0,
	chunks: [],
	processedChunks: [],
	backwardsAudio: false,
	forwardBackwardsChunkIndex: 0,
	forwardBackwardsWordIndex: 0,
	customStart: false,
	karaokeApplied: false,
	stopKaraoke: null,
	ttsCreating: false,
};

// Create the global state
export const globalState: GlobalStateType = { ...initialState };

// Get a value from the global state
export const getGlobalState = <K extends keyof GlobalStateType>(key: K): GlobalStateType[K] => globalState[key];

// Set a single value in the global state
export const setGlobalState = <K extends keyof GlobalStateType>(key: K, value: GlobalStateType[K]) => {
	globalState[key] = value;
};

// Reset all values to default
export const resetGlobalState = (excludeKeys: Array<keyof GlobalStateType> = []) => {
	(Object.keys(initialState) as Array<keyof GlobalStateType>)
		.filter((key) => !excludeKeys.includes(key))
		.forEach((key) => {
			const value = initialState[key];
			if (Array.isArray(value)) {
				(globalState[key] as typeof value) = [...value];
			} else if (typeof value === "object" && value !== null) {
				(globalState[key] as typeof value) = { ...value };
			} else {
				(globalState[key] as typeof value) = value;
			}
		});
};

// Set multiple states at once
export const setBatchGlobalState = (updates: Partial<typeof globalState>) => {
	Object.assign(globalState, updates);
};
const CHUNK_SIZE = 500; // Adjust this value based on your TTS service's limit
export const TTS_LOCALSTORAGE_KEY = "ttsData";

export const getSummaryTextUtil = async (
	resourceId: string,
	localData: any,
	setData: (data: any) => void,
	setUserSettings: (data: any) => void,
	rerenderWithPlainText: (data: any, plainText: string, file_text: boolean) => any,
	id: string,
	storage_key: string,
	file_id: string,
	file_text: boolean,
	setRawFileText: (data: any) => void
) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		const uuid = auth.currentUser?.uid;
		if (!token) throw new Error("No token available");
		let parsedData: SummaryViewer | string = { pages: [] };
		if (file_text && uuid) {
			let fileData = await argumentServices.getUserFile(token, uuid, file_id);
			parsedData = fileData.ocr_result!;
			setRawFileText(fileData.ocr_result);
		} else {
			try {
				const summaryContent = await summaryServices.getSummary(resourceId, token);
				const userSettings = await settingsServices.getUserSettings(auth.currentUser!.uid, token);
				setUserSettings(userSettings);
				parsedData = JSON.parse(summaryContent.content);
				addOrUpdateItem(id, parsedData, storage_key);
			} catch (error) {
				console.warn(error);
				parsedData = localData; // Fallback to local data in case of error
			}
		}
		const plainText = extractPlainText(parsedData, file_text);
		const rerenderedData = rerenderWithPlainText(parsedData, plainText, file_text);
		if (file_id) id = file_id;
		addOrUpdateItem(id, rerenderedData, storage_key);

		setData(rerenderedData);
	} catch (error) {
		console.error("Error fetching summary:", error);
	}
};
export const checkStatusBar = async () => {
    const info = await Device.getInfo();

    if (info.platform === 'ios') {
        const statusBarInfo = await StatusBar.getInfo();
        console.log('Status bar visible:', statusBarInfo.visible);

        // Status bar height (you can infer it, though it's often 20pt or 44pt in height)
        const statusBarHeight = statusBarInfo.visible ? (info.model.includes('iPhoneX') ?  "clamp(200px, 15vh, 350px)" :  "clamp(150px, 15vh, 350px)") : "";
        console.log('Status bar height:', statusBarHeight);

        return statusBarHeight;
    } else {
        console.log('Not an iOS device');
        return "";
    }
};
export function extractPlainText(data: SummaryViewer | string, file_text: boolean): string {
	if (file_text) {
		// For file_text case, the data is already a string
		return data as string;
	} else {
		const stripHtml = (html: string) => {
			const tmp = document.createElement("DIV");
			tmp.innerHTML = html;
			return tmp.textContent || tmp.innerText || "";
		};

		return (data as SummaryViewer).pages
			.map((page) => page.paragraphs.map((paragraph) => paragraph.blocks.map((block) => stripHtml(block.content)).join("\n")).join("\n\n"))
			.join("\n\n\n");
	}
}

export function rerenderWithPlainText(data: SummaryViewer, plainText: string, file_text: boolean): SummaryViewer {
	if (file_text) {
		// For file_text case, create a new SummaryViewer structure
		const sentences = plainText.match(/[^.!?]+[.!?]+/g) || [];
		const BLOCKS_PER_PARAGRAPH = 3;
		const PARAGRAPHS_PER_PAGE = 6;

		const pages = [];
		let currentPageIndex = 0;
		let currentParagraphIndex = 0;
		let currentBlockIndex = 0;

		while (sentences.length > 0) {
			const currentPage = {
				paragraphs: [] as any[],
			};

			// Create paragraphs for current page
			for (let p = 0; p < PARAGRAPHS_PER_PAGE; p++) {
				if (sentences.length === 0) break;

				const currentParagraph = {
					blocks: [] as any[],
				};

				// Create blocks for current paragraph
				for (let b = 0; b < BLOCKS_PER_PARAGRAPH; b++) {
					if (sentences.length === 0) break;

					const sentence = sentences.shift()?.trim();
					if (sentence) {
						const block = {
							id: `block_${currentPageIndex}_${currentParagraphIndex}_${currentBlockIndex}`,
							content: sentence,
							type: "text",
							style: {
								bold: false,
								italic: false,
								colors: ["var(--txt-color)"],
								classes: [],
							},
						};
						currentParagraph.blocks.push(block);
						currentBlockIndex++;
					}
				}

				if (currentParagraph.blocks.length > 0) {
					currentPage.paragraphs.push(currentParagraph);
					currentParagraphIndex++;
				}
				currentBlockIndex = 0;
			}

			if (currentPage.paragraphs.length > 0) {
				pages.push(currentPage);
				currentPageIndex++;
			}
			currentParagraphIndex = 0;
		}

		return {
			pages,
		} as SummaryViewer;
	} else {
		const textLines = plainText.split("\n");
		let lineIndex = 0;

		return {
			...(data as SummaryViewer),
			pages: (data as SummaryViewer).pages.map((page) => ({
				...page,
				paragraphs: page.paragraphs.map((paragraph) => ({
					...paragraph,
					blocks: paragraph.blocks.map((block) => {
						const newContent = textLines[lineIndex++] || "";
						return {
							...block,
							content: applyOriginalStyling(block.content, newContent),
						};
					}),
				})),
			})),
		};
	}
}
// Helper function to apply original styling
const applyOriginalStyling = (originalContent: string, newContent: string) => {
	// Since we can't use DOMParser in React directly, we'll use a regex-based approach
	const spanRegex = /<span[^>]*class="([^"]*)"[^>]*style="([^"]*)"[^>]*>(.*?)<\/span>/g;
	let styledContent = newContent;
	let match;

	while ((match = spanRegex.exec(originalContent)) !== null) {
		const [, className, style, text] = match;
		const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
		const regex = new RegExp(escapedText, "g");
		styledContent = styledContent.replace(regex, `<span class="${className}" style="${style}">${text}</span>`);
	}

	return styledContent;
};

export function rerenderWithoutStyling(data: SummaryViewer | string, file_text: boolean): SummaryViewer {
	if (file_text) {
		const sentences = (data as string).match(/[^.!?]+[.!?]+/g) || [];
		const BLOCKS_PER_PARAGRAPH = 3;
		const PARAGRAPHS_PER_PAGE = 6;

		let currentChunkIndexInRender = 0;
		let currentWordIndexInChunkInRender = 0;

		const pages: any[] = [];
		let currentPageIndex = 0;
		let currentParagraphIndex = 0;
		let currentBlockIndex = 0;

		while (sentences.length > 0) {
			const currentPage: any = {
				id: `page_${currentPageIndex}`,
				paragraphs: [],
			};

			for (let p = 0; p < PARAGRAPHS_PER_PAGE && sentences.length > 0; p++) {
				const currentParagraph: any = {
					blocks: [],
				};

				for (let b = 0; b < BLOCKS_PER_PARAGRAPH && sentences.length > 0; b++) {
					const sentence = sentences.shift()?.trim();
					if (sentence) {
						const { content: newContent, chunkIndex, wordIndex } = wrapWordsWithSpans(sentence, currentChunkIndexInRender, currentWordIndexInChunkInRender);

						currentChunkIndexInRender = chunkIndex;
						currentWordIndexInChunkInRender = wordIndex;

						currentParagraph.blocks.push({
							id: `block_${currentPageIndex}_${currentParagraphIndex}_${currentBlockIndex}`,
							content: newContent,
							type: "text",
							style: {
								bold: false,
								italic: false,
								colors: ["var(--txt-color)"],
								classes: [],
							},
						});
						currentBlockIndex++;
					}
				}

				if (currentParagraph.blocks.length > 0) {
					currentPage.paragraphs.push(currentParagraph);
					currentParagraphIndex++;
				}
				currentBlockIndex = 0;
			}

			if (currentPage.paragraphs.length > 0) {
				pages.push(currentPage);
				currentPageIndex++;
			}
			currentParagraphIndex = 0;
		}

		return { pages } as SummaryViewer;
	} else {
		// Original logic for SummaryViewer data
		let currentChunkIndexInRender = 0;
		let currentWordIndexInChunkInRender = 0;

		return {
			...(data as SummaryViewer),
			pages: (data as SummaryViewer).pages.map((page) => ({
				...page,
				paragraphs: page.paragraphs.map((paragraph) => ({
					...paragraph,
					blocks: paragraph.blocks.map((block) => {
						const {
							content: newContent,
							chunkIndex,
							wordIndex,
						} = wrapWordsWithSpans(block.content, currentChunkIndexInRender, currentWordIndexInChunkInRender);
						currentChunkIndexInRender = chunkIndex;
						currentWordIndexInChunkInRender = wordIndex;
						return {
							...block,
							content: newContent,
						};
					}),
				})),
			})),
		};
	}
}

function wrapWordsWithSpans(content: string, startChunkIndex: number, startWordIndex: number): { content: string; chunkIndex: number; wordIndex: number } {
	const allSpeechMarks = getGlobalState("allSpeechMarks");

	let currentChunkIndex = startChunkIndex;
	let currentWordIndex = startWordIndex;

	const wrapWord = (word: string, chunkIndex: number, wordIndex: number) => {
		return `<span id="word-${chunkIndex}-${wordIndex}">${word}</span>`;
	};
	const strippedText = content.replace(/<[^>]+>/g, "");

	let contentParts = strippedText.split(/(<[^>]*>|&nbsp;|\s+)/);

	// console.log("Starting content:", content);
	// console.log("Starting at chunk:", currentChunkIndex, "word:", currentWordIndex);

	let wrappedContent = [];
	let unmatchedWords: string[] = [];

	const wrapUnmatched = () => {
		if (unmatchedWords.length > 0) {
			wrappedContent.push(wrapWord(unmatchedWords.join(" "), currentChunkIndex, currentWordIndex));
			unmatchedWords = [];
			currentWordIndex++;
		}
	};

	for (let i = 0; i < contentParts.length; i++) {
		let part = contentParts[i];

		if (part.startsWith("<") || part.trim() === "" || part === "&nbsp;") {
			wrapUnmatched();
			wrappedContent.push(part);
			continue;
		}

		// console.log("Processing content part:", part);

		if (currentChunkIndex < allSpeechMarks.length) {
			const currentChunk = allSpeechMarks[currentChunkIndex];
			let matched = false;
			let combinedSpeechMark = "";
			let startWordIndex = currentWordIndex;
			let endWordIndex = currentWordIndex;
			let matchedSpeechMarks = [];

			while (endWordIndex < currentChunk.length && !matched) {
				combinedSpeechMark += currentChunk[endWordIndex].value;
				matchedSpeechMarks.push(currentChunk[endWordIndex].value);
				// console.log("Checking combined speechMark:", combinedSpeechMark);

				const wordToMatch = part.replace(/[.,!?;]$/, "");
				const speechMarkToMatch = combinedSpeechMark.replace(/[.,!?;]$/, "");

				if (wordToMatch.toLowerCase() === speechMarkToMatch.toLowerCase()) {
					wrapUnmatched();
					let wrappedParts = [];
					for (let j = 0; j < matchedSpeechMarks.length; j++) {
						wrappedParts.push(wrapWord(matchedSpeechMarks[j], currentChunkIndex, currentWordIndex + j));
					}
					// Preserve original punctuation
					const punctuation = part.match(/[.,!?;]$/);
					if (punctuation) {
						wrappedParts[wrappedParts.length - 1] = wrappedParts[wrappedParts.length - 1].slice(0, -7) + punctuation[0] + "</span>";
					}
					wrappedContent.push(wrappedParts.join(""));
					// console.log("Wrapped split word:", wrappedParts.join(''));
					matched = true;
					currentWordIndex = endWordIndex + 1;
				} else if (!wordToMatch.toLowerCase().startsWith(speechMarkToMatch.toLowerCase())) {
					break;
				}
				endWordIndex++;
			}

			if (!matched) {
				unmatchedWords.push(part);
				// console.log("Accumulating unmatched:", part);
			}

			if (currentWordIndex >= currentChunk.length) {
				// console.log("Moving to next chunk");
				wrapUnmatched();
				currentChunkIndex++;
				currentWordIndex = 0; // Reset currentWordIndex to 0 when moving to a new chunk
			}
		} else {
			unmatchedWords.push(part);
		}
	}

	wrapUnmatched();

	const finalContent = wrappedContent.join("");

	return {
		content: finalContent,
		chunkIndex: currentChunkIndex,
		wordIndex: currentWordIndex,
	};
}

function normalizeColor(color: string): string {
    // Handle hex colors
    if (color.startsWith('#')) {
        return color;
    }
    
    // Handle rgb/rgba colors
    if (color.startsWith('rgb')) {
        // Convert "rgb(r, g, b)" to hex
        const values = color.match(/\d+/g);
        if (values && values.length >= 3) {
            const hex = '#' + values.slice(0, 3)
                .map(x => parseInt(x).toString(16).padStart(2, '0'))
                .join('');
            return hex;
        }
    }
    
    return color; // Return original if no conversion needed
}

export function textFormatation(
    isSpan: { style: string; active: boolean },
    txtColor: { color: string, active: boolean },
    showColorPicker: boolean,
    activeElement: HTMLElement | null
) {
    // Store the current selection
    const selection = window.getSelection();
    if (!selection || selection.isCollapsed) return;

    // Store the range information
    const range = selection.getRangeAt(0);
    const rangeState = {
        startContainer: range.startContainer,
        startOffset: range.startOffset,
        endContainer: range.endContainer,
        endOffset: range.endOffset
    };

    if (showColorPicker && activeElement) {
        activeElement.focus();
        
        // Restore the selection
        const newRange = document.createRange();
        newRange.setStart(rangeState.startContainer, rangeState.startOffset);
        newRange.setEnd(rangeState.endContainer, rangeState.endOffset);
        selection.removeAllRanges();
        selection.addRange(newRange);
    }

    const hasStyle = (element: HTMLElement, styleType: string): boolean => {
        const computedStyle = window.getComputedStyle(element);
        const classList = element.classList;

        switch (styleType) {
            case "bold":
                return computedStyle.fontWeight === "700" || computedStyle.fontWeight === "bold" || classList.contains("bold");
            case "italic":
                return computedStyle.fontStyle === "italic" || classList.contains("italic");
            case "color":
                return computedStyle.color === txtColor.color;
            case "bullet":
                return element.tagName === "LI" || classList.contains("bullet-point");
            default:
                return false;
        }
    };

    // Helper function to apply style and class
    const applyStyle = (element: HTMLElement, styleType: string, isTxtColor: boolean): void => {
        if (!element) return;

        switch (styleType) {
            case "bold":
                element.style.fontWeight = "700";
                element.classList.add("bold");
                break;
            case "italic":
                element.style.fontStyle = "italic";
                element.classList.add("italic");
                break;
            case "bullet":
                // If the element isn't already in a list, create a new list
                if (element.tagName !== "LI") {
                    const ul = document.createElement("ul");
                    const li = document.createElement("li");
                    li.classList.add("bullet-point");
                    
                    // Copy the content from the original element
                    li.innerHTML = element.innerHTML;
                    ul.appendChild(li);
                    
                    // Replace the original element with the list
                    element.parentNode?.replaceChild(ul, element);
                }
                break;
            default:
                if (isTxtColor && txtColor.color) {
                    try {
                        element.style.color = txtColor.color;
                        element.classList.add("colored");
                    } catch (e) {
                        console.warn("Failed to apply color:", e);
                    }
                }
                break;
        }
    };

    const removeStyle = (element: HTMLElement, styleType: string): void => {
        switch (styleType) {
            case "bold":
                element.style.fontWeight = "normal";
                element.classList.remove("bold");
                break;
            case "italic":
                element.style.fontStyle = "normal";
                element.classList.remove("italic");
                break;
            case "color":
                element.style.color = "";
                element.classList.remove("colored");
                break;
            case "bullet":
                if (element.tagName === "LI") {
                    const ul = element.parentElement;
                    if (ul && ul.childNodes.length === 1) {
                        // If this is the only list item, remove the entire list
                        const span = document.createElement("span");
                        span.innerHTML = element.innerHTML;
                        ul.parentNode?.replaceChild(span, ul);
                    } else {
                        // Otherwise, just remove this list item
                        element.remove();
                    }
                }
                element.classList.remove("bullet-point");
                break;
        }
    };

    // Helper function to reset all styles on an element
    const resetAllStyles = (element: HTMLElement): void => {
        element.style.fontWeight = "normal";
        element.style.fontStyle = "normal";
        element.style.color = "";
        element.classList.remove("bold", "italic", "colored", "bullet-point");
        
        // If it's a list item, convert it back to a regular span
        if (element.tagName === "LI") {
            const span = document.createElement("span");
            span.innerHTML = element.innerHTML;
            element.parentNode?.replaceChild(span, element);
        }
    };

    // Rest of the existing functions remain the same...
    const splitTextAndPreserveStyle = (element: HTMLElement, range: Range): void => {
        const originalText = element.textContent || "";
        const selectedText = range.toString();
        const startOffset = range.startOffset;
        const endOffset = range.endOffset;

        if (startOffset > 0) {
            const beforeSpan = document.createElement("span");
            beforeSpan.textContent = originalText.substring(0, startOffset);
            beforeSpan.style.cssText = element.style.cssText;
            beforeSpan.className = element.className;
            element.parentNode?.insertBefore(beforeSpan, element);
        }

        const selectedSpan = document.createElement("span");
        selectedSpan.textContent = selectedText;
        resetAllStyles(selectedSpan);
        element.parentNode?.insertBefore(selectedSpan, element);

        if (endOffset < originalText.length) {
            const afterSpan = document.createElement("span");
            afterSpan.textContent = originalText.substring(endOffset);
            afterSpan.style.cssText = element.style.cssText;
            afterSpan.className = element.className;
            element.parentNode?.insertBefore(afterSpan, element);
        }

        element.remove();
    };

    const toggleStyleOnElement = (element: HTMLElement, styleType: string): void => {
        const shouldNormalize = range.toString().length < element.textContent!.length;

        if (shouldNormalize) {
            splitTextAndPreserveStyle(element, range);
        } else {
            if (hasStyle(element, styleType)) {
                removeStyle(element, styleType);
            } else {
                applyStyle(element, styleType, txtColor.active);
            }
        }
    };

    const createStyledSpan = (text: string, styleType: string, isTxtColor: boolean): HTMLElement => {
        let element: HTMLElement;
		const ul = document.createElement("ul");

        if (styleType === "bullet") {
            element = document.createElement("li");
            element.classList.add("bullet-point");
            ul.appendChild(element);
        } else {
            element = document.createElement("span");
        }
        
        element.textContent = text;
        if (styleType !== "bullet") {
            applyStyle(element, styleType, isTxtColor);
        }
        
        return styleType === "bullet" ? ul : element;
    };

    const findExactMatchingElement = (range: Range): HTMLElement | null => {
        const selectedText = range.toString().trim();
        const container = range.commonAncestorContainer as HTMLElement;
        const elementToCheck = container.nodeType === Node.TEXT_NODE ? container.parentElement : container;

        if (!elementToCheck) return null;

        if (elementToCheck.textContent?.trim() === selectedText) {
            return elementToCheck;
        }

        const children = elementToCheck.getElementsByTagName("*");
        for (const child of Array.from(children)) {
            if (child instanceof HTMLElement && child.textContent?.trim() === selectedText) {
                return child;
            }
        }

        return null;
    };

    try {
        const exactMatch = findExactMatchingElement(range);

        if (!range.startContainer || !range.endContainer) {
            console.warn("Invalid range detected");
            return;
        }

        if (exactMatch) {
            toggleStyleOnElement(exactMatch, isSpan.style);
        } else {
            const selectedContent = range.toString();
            if (!selectedContent.trim()) return;

            if (range.startContainer === range.endContainer && range.startContainer.nodeType === Node.TEXT_NODE) {
                const text = range.toString();
                const newElement = createStyledSpan(text, isSpan.style, txtColor.active);
                range.deleteContents();
                range.insertNode(newElement);
            } else {
                const fragment = document.createDocumentFragment();
                const text = range.toString();
                const newElement = createStyledSpan(text, isSpan.style, txtColor.active);
                fragment.appendChild(newElement);
                range.deleteContents();
                range.insertNode(fragment);
            }
        }

        selection.collapseToEnd();
    } catch (error) {
        console.error("Error in text formatting:", error);
    }
}

// TTS PLAYER BUTTONS

export const applyKaraokeEffectUtil = (
	speechMarks: any[],
	audioRef: React.RefObject<HTMLAudioElement>,
	setCurrentWordIndex: React.Dispatch<React.SetStateAction<number>>
) => {
	setGlobalState("karaokeApplied", true);
	let isBackwards = getGlobalState("backwardsAudio");

	let animationFrameId: number | null = null;
	let lastHighlightedIndex = -1;
	let lastUpdateTime = 0;
	const UPDATE_INTERVAL = 100; // Update every 100ms

	// Scroll configuration
	const SCROLL_MARGIN = 150; // Pixels from top/bottom of viewport
	const SMOOTH_SCROLL_OPTIONS = {
		behavior: 'smooth',
		block: 'nearest',
	} as ScrollIntoViewOptions;

	const scrollToWord = (element: HTMLElement) => {
		const rect = element.getBoundingClientRect();
		const viewportHeight = window.innerHeight;

		// Check if the element is too close to the top or bottom of the viewport
		if (rect.top < SCROLL_MARGIN || rect.bottom > (viewportHeight - SCROLL_MARGIN)) {
			element.scrollIntoView(SMOOTH_SCROLL_OPTIONS);
		}
	};

	const highlightWord = (currentTime: number) => {
		const now = performance.now();
		if (now - lastUpdateTime < UPDATE_INTERVAL) {
			animationFrameId = requestAnimationFrame(() => {
				if (audioRef.current) {
					highlightWord(audioRef.current.currentTime);
				}
			});
			return;
		}
		lastUpdateTime = now;
		const currentChunkIndexPlaying = getGlobalState("currentChunkIndexPlaying");

		let index = speechMarks.findIndex((mark) => mark.time > currentTime * 1000);
		if (index === -1) index = speechMarks.length;

		if (index !== lastHighlightedIndex && index < speechMarks.length) {
			// Remove highlight from previous word
			if (lastHighlightedIndex >= 0) {
				const prevWord = document.getElementById(`word-${currentChunkIndexPlaying}-${lastHighlightedIndex}`);
				if (prevWord) prevWord.classList.remove("highlight");
			}

			// Add highlight to current word and scroll if needed
			const currentWord = document.getElementById(`word-${currentChunkIndexPlaying}-${index}`);
			if (currentWord) {
				currentWord.classList.add("highlight");
				scrollToWord(currentWord);
			}

			setCurrentWordIndex(index);
			lastHighlightedIndex = index;
		}

		animationFrameId = requestAnimationFrame(() => {
			if (audioRef.current) {
				highlightWord(audioRef.current.currentTime);
			}
		});
	};

	const startHighlighting = () => {
		const delay = isBackwards ? 300 : 0; // Longer delay for backwards on mobile
		setTimeout(() => {
			if (audioRef.current) {
				highlightWord(audioRef.current.currentTime);
			}
		}, delay);
	};

	const stopHighlighting = () => {
		if (animationFrameId !== null) {
			cancelAnimationFrame(animationFrameId);
		}
	};

	startHighlighting();

	return stopHighlighting;
};
export async function handleTimeUpdate(
	audioRef: React.RefObject<HTMLAudioElement>,
	isPlaying: boolean,
	currentChunkIndex: number,
	setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>,
	applyKaraokeEffect: (startIndexWord: number, startIndexChunk: number) => void,
	karaokeTimeoutRef: React.MutableRefObject<number | null>,
	setSpeechMarks: React.Dispatch<React.SetStateAction<any>>,
	setAudioSrc: React.Dispatch<React.SetStateAction<string>>,
	setAllAudioSrc: React.Dispatch<React.SetStateAction<string[]>>,
	setAllSpeechMarks: React.Dispatch<React.SetStateAction<any[]>>,
	setData: React.Dispatch<React.SetStateAction<any>>,
	localData: SummaryViewer,
	speechMarks: SpeechMark[],
	setCurrentWordIndex: React.Dispatch<React.SetStateAction<number>>,
	id: string,
	file_text: boolean,
	raw_file_text: string
) {
	let currentWordIndex = getGlobalState("playingWordsIndex");
	let currentChunkIndexGlobal = getGlobalState("currentChunkIndexGlobal");
	let currentlyProcessing = getGlobalState("chunkProcessingIndex");
	let chunks = getGlobalState("chunks");

	if (currentChunkIndexGlobal > chunks.length) return;

	if (audioRef.current && audioRef.current.duration) {
		const timeRemaining = audioRef.current.duration - audioRef.current.currentTime;
		// Check if less than 5 seconds are remaining
		let hasProcessedChunk = getGlobalState("hasProcessedChunk");
		let ttsSet = getGlobalState("ttsSettings");
		let tokenBearer = getGlobalState("tokenBearer");
		// console.log(timeRemaining,hasProcessedChunk,getGlobalState('backwardsAudio'))
		if (timeRemaining > 0 && timeRemaining < 15 && !hasProcessedChunk && !getGlobalState("backwardsAudio")) {
			// console.warn("time < 15", getGlobalState("playingNextChunk"), currentChunkIndexx, timeRemaining);

			currentlyProcessing++;
			// console.log(currentlyProcessing, chunks.length);
			setGlobalState("chunkProcessingIndex", currentlyProcessing);

			if (ttsSet != null && chunks.length > currentlyProcessing) {
				console.log("why the fock");
				setGlobalState("hasProcessedChunk", true);
				await processNextChunk(tokenBearer, ttsSet, localData, setData, id, file_text, raw_file_text);
			}

			// console.warn("Warning: Audio is near the end.");
		} else if (timeRemaining === 0) {
			console.warn("time 0", getGlobalState("playingNextChunk"), timeRemaining);
			let indexPlaying = getGlobalState("currentChunkIndexGlobal") + 1;
			setGlobalState("currentChunkIndexGlobal", indexPlaying);
			console.log(chunks.length, currentChunkIndexGlobal);
			const prevWord = document.getElementById(`word-${indexPlaying}-${currentWordIndex}`);
			if (prevWord) prevWord.classList.remove("highlight");
			// console.warn("PLAY NEXT ", 0, getGlobalState("currentChunkIndexGlobal"));
			if (chunks.length === currentChunkIndexGlobal) {
				setGlobalState("chunkProcessingIndex", chunks.length - 1);
				setGlobalState("playingNextChunk", false);
				setGlobalState("hasProcessedChunk", false);

				audioRef.current.pause();
				setIsPlaying(false);
				document.querySelectorAll('[id^="word-"]').forEach((word) => {
					word.classList.remove("highlight");
				});
			} else {
				playNextChunk(
					audioRef,
					isPlaying,
					0,
					setIsPlaying,
					applyKaraokeEffect,
					karaokeTimeoutRef,
					setSpeechMarks,
					setAudioSrc,
					setAllAudioSrc,
					setAllSpeechMarks,
					setData,
					localData,
					speechMarks,
					setCurrentWordIndex,
					id,
					file_text,
					raw_file_text
				);
				// setBatchGlobalState({
				//     currentChunkIndexPlaying: chunkindex,
				//     currentChunkIndexGlobal: chunkindex + 1,
				//     forwardBackwardsChunkIndex: chunkindex,
				// });
			}
			setGlobalState("playingNextChunk", true);
		} else if (getGlobalState("backwardsAudio")) {
			console.log("INDIETRO NON PAASI DAL PLAYUTIL", getGlobalState("currentChunkIndexGlobal"));
			setBatchGlobalState({
				backwardsAudio: false,
				playingNextChunk: true,
				hasProcessedChunk: false,
			});
			let allAudioSrc = getGlobalState("allAudioSrc");
			audioRef.current!.src = allAudioSrc[getGlobalState("currentChunkIndexGlobal")];
			audioRef.current.play();
		}
	}
}

export const playPauseUtil = (
	audioRef: React.RefObject<HTMLAudioElement>,
	isPlaying: boolean,
	currentWordIndex: number,
	currentChunkIndex: number,
	setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>,
	applyKaraokeEffect: (startIndexWord: number, startIndexChunk: number) => void,
	karaokeTimeoutRef: React.MutableRefObject<number | null>,
	setSpeechMarks: React.Dispatch<React.SetStateAction<any>>,
	setAudioSrc: React.Dispatch<React.SetStateAction<string>>,
	setAllAudioSrc: React.Dispatch<React.SetStateAction<string[]>>,
	setAllSpeechMarks: React.Dispatch<React.SetStateAction<any[]>>,
	setData: React.Dispatch<React.SetStateAction<any>>,
	localData: SummaryViewer,
	speechMarks: SpeechMark[],
	setCurrentWordIndex: React.Dispatch<React.SetStateAction<number>>,
	id: string,
	file_text: boolean,
	raw_file_text: string
) => {
	let currentChunkIndexPlaying = getGlobalState("currentChunkIndexPlaying");
	document.querySelectorAll('[id^="word-"]').forEach((word) => {
		word.classList.remove("highlight");
	});
	// audioRef.current?.removeEventListener("timeupdate", () => {});
	let stopKaraokeAnimation: any = null;
	console.warn("QUAN ENTRI MA NON VAI AVANTI")
	if (audioRef.current) {
		console.log("AUDI REF",isPlaying)
		if (isPlaying) {
			audioRef.current.addEventListener("timeupdate", () =>
				handleTimeUpdate(
					audioRef,
					isPlaying,
					0,
					setIsPlaying,
					applyKaraokeEffect,
					karaokeTimeoutRef,
					setSpeechMarks,
					setAudioSrc,
					setAllAudioSrc,
					setAllSpeechMarks,
					setData,
					localData,
					speechMarks,
					setCurrentWordIndex,
					id,
					file_text,
					raw_file_text
				)
			);

			stopKaraokeAnimation = getGlobalState("stopKaraoke");
            console.log(getGlobalState("backwardsAudio"),getGlobalState("playingNextChunk"))
			console.log(getGlobalState("chunks").length - 1, getGlobalState("currentChunkIndexGlobal"));
			if (getGlobalState("backwardsAudio") || getGlobalState("playingNextChunk")) {
				setGlobalState("backwardsAudio", false);
				setGlobalState("playingNextChunk", false);
				console.log("BACK WARDSSS");
				stopKaraokeAnimation();
				stopKaraokeAnimation = applyKaraokeEffectUtil(speechMarks, audioRef, setCurrentWordIndex);
				setGlobalState("stopKaraoke", stopKaraokeAnimation);
				audioRef.current.play();
				setIsPlaying(true);
			} else if (getGlobalState("chunks").length < getGlobalState("currentChunkIndexGlobal")) {
				audioRef.current.pause();
				setIsPlaying(false);
				stopKaraokeAnimation();
			} else {
				audioRef.current.pause();
				setIsPlaying(false);
				console.log("PAUSE",currentWordIndex, currentChunkIndex, currentChunkIndexPlaying);
				setCurrentWordIndex(currentWordIndex)
				stopKaraokeAnimation();
			}
		} else {
			console.log(getGlobalState("chunks").length - 1, getGlobalState("currentChunkIndexGlobal"));
			if (getGlobalState("chunks").length - 1 <= getGlobalState("currentChunkIndexGlobal") && !getGlobalState("playingNextChunk")) {
				setGlobalState("hasProcessedChunk", true);
			}
			audioRef.current.addEventListener("timeupdate", () =>
				handleTimeUpdate(
					audioRef,
					isPlaying,
					currentWordIndex,
					setIsPlaying,
					applyKaraokeEffect,
					karaokeTimeoutRef,
					setSpeechMarks,
					setAudioSrc,
					setAllAudioSrc,
					setAllSpeechMarks,
					setData,
					localData,
					speechMarks,
					setCurrentWordIndex,
					id,
					file_text,
					raw_file_text
				)
			);
			console.log("About to play audio");
			audioRef.current.play();
			console.log("After play() call - is paused?:", audioRef.current.paused);			
			setIsPlaying(true);
			stopKaraokeAnimation = applyKaraokeEffectUtil(speechMarks, audioRef, setCurrentWordIndex);
			setGlobalState("stopKaraoke", stopKaraokeAnimation);
			console.log(currentWordIndex, currentChunkIndex, currentChunkIndexPlaying);
			// Add event listeners to track what's happening
audioRef.current.addEventListener('playing', () => {
    console.log("Audio started playing");
});

audioRef.current.addEventListener('pause', () => {
    console.log("Audio paused");
});

// Also check if anything else is stopping the playback
console.log("Current listeners:", {
    timeupdate: audioRef.current.onplaying,
    playing: audioRef.current.onplaying,
    pause: audioRef.current.onpause
});

// And verify the audio src
console.log("Audio source:", audioRef.current.src);

		}
	}else{
		audioRef.current!.pause();
				setIsPlaying(false);
				console.log("PAUSE",currentWordIndex, currentChunkIndex, currentChunkIndexPlaying);
				setCurrentWordIndex(currentWordIndex)
				stopKaraokeAnimation();
	}
};

export const forwardUtil = (
	audioRef: React.RefObject<HTMLAudioElement>,
	updateKaraokeHighlight: () => void,
	setAudioSrc: React.Dispatch<React.SetStateAction<string>>,
	setCurrentChunkIndex: React.Dispatch<React.SetStateAction<number>>,
	setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>,
	setSpeechMarks: React.Dispatch<React.SetStateAction<any>>,
	setCurrentWordIndex: React.Dispatch<React.SetStateAction<number>>
) => {
	let stop: any = null;
	stop = getGlobalState("stopKaraoke");
	document.querySelectorAll('[id^="word-"]').forEach((word) => {
		word.classList.remove("highlight");
	});
	audioRef.current?.pause();

	audioRef.current?.removeEventListener("timeupdate", () => {});
	if (audioRef.current) {
		audioRef.current.currentTime += 5;
		const currentTime = audioRef.current?.currentTime || 0;
		const duration = audioRef.current?.duration;
		let allAudioSrc = getGlobalState("allAudioSrc");
		let allSpeechMarks = getGlobalState("allSpeechMarks");
		let currentChunkIndexPlaying = getGlobalState("currentChunkIndexPlaying");
		let chunkindex = getGlobalState("forwardBackwardsChunkIndex");
		let chunks = getGlobalState("chunks");

		if (currentTime >= duration - 5 && chunks.length > currentChunkIndexPlaying) {
			audioRef.current?.removeEventListener("timeupdate", () => {});
			chunkindex = Math.min(allAudioSrc.length - 1, currentChunkIndexPlaying + 1);

			setBatchGlobalState({
				currentChunkIndexPlaying: chunkindex,
				currentChunkIndexGlobal: chunkindex + 1,
				forwardBackwardsChunkIndex: chunkindex,
			});
			setCurrentChunkIndex(chunkindex);
			setAudioSrc(allAudioSrc[chunkindex]);
			setSpeechMarks(allSpeechMarks[chunkindex]);
			setIsPlaying(true);
		}
		stop();
		stop = applyKaraokeEffectUtil(allSpeechMarks[chunkindex], audioRef, setCurrentWordIndex);
		setGlobalState("stopKaraoke", stop);
		updateKaraokeHighlight();
	}
};

export const backwardUtil = (
	audioRef: React.RefObject<HTMLAudioElement>,
	updateKaraokeHighlight: () => void,
	setAudioSrc: React.Dispatch<React.SetStateAction<string>>,
	setCurrentChunkIndex: React.Dispatch<React.SetStateAction<number>>,
	setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>,
	setSpeechMarks: React.Dispatch<React.SetStateAction<any>>,
	setCurrentWordIndex: React.Dispatch<React.SetStateAction<number>>
) => {
	let stop: any = null;
	stop = getGlobalState("stopKaraoke");
	document.querySelectorAll('[id^="word-"]').forEach((word) => {
		word.classList.remove("highlight");
	});
	audioRef.current?.pause();

	audioRef.current?.removeEventListener("timeupdate", () => {});
	if (audioRef.current) {
		audioRef.current.currentTime -= 10;
		const currentTime = audioRef.current?.currentTime || 0;
		const duration = audioRef.current?.duration;
		let currentChunkIndexPlaying = getGlobalState("currentChunkIndexPlaying");
		let allAudioSrc = getGlobalState("allAudioSrc");
		let allSpeechMarks = getGlobalState("allSpeechMarks");
		let chunks = getGlobalState("chunks");
		let chunkindex = getGlobalState("forwardBackwardsChunkIndex");
		setBatchGlobalState({
			backwardsAudio: true,
			playingNextChunk: false,
			hasProcessedChunk: true,
		});
		if (currentTime == 0) {
			audioRef.current?.removeEventListener("timeupdate", () => {});

			if (currentChunkIndexPlaying === chunks.length - 1) setGlobalState("currentChunkIndexGlobal", currentChunkIndexPlaying - 1);
			// Go back by one, but don't go below 0
			chunkindex = Math.max(0, currentChunkIndexPlaying - 1);
			console.log(chunkindex, "SARA NON 0", getGlobalState("currentChunkIndexGlobal"));
			setBatchGlobalState({
				currentChunkIndexPlaying: chunkindex,
				hasProcessedChunk: false,
				forwardBackwardsChunkIndex: chunkindex,
				currentChunkIndexGlobal: chunkindex,
			});
			setCurrentChunkIndex(chunkindex);
			setAudioSrc(allAudioSrc[chunkindex]);
			setSpeechMarks(allSpeechMarks[chunkindex]);
			setIsPlaying(true);
			setGlobalState("hasProcessedChunk", false);
		}
		stop();

		stop = applyKaraokeEffectUtil(allSpeechMarks[chunkindex], audioRef, setCurrentWordIndex);
		setGlobalState("stopKaraoke", stop);
		updateKaraokeHighlight();
	}
};

export const updateKaraokeHighlightUtil = (
	audioRef: React.RefObject<HTMLAudioElement>,
	isPlaying: boolean,
	setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>,
	applyKaraokeEffect: (startIndexWord: number, startIndexChunk: number) => void,
	karaokeTimeoutRef: React.MutableRefObject<number | null>,
	setSpeechMarks: React.Dispatch<React.SetStateAction<any>>,
	setAudioSrc: React.Dispatch<React.SetStateAction<string>>,
	setAllAudioSrc: React.Dispatch<React.SetStateAction<string[]>>,
	setAllSpeechMarks: React.Dispatch<React.SetStateAction<any[]>>,
	setData: React.Dispatch<React.SetStateAction<any>>,
	localData: SummaryViewer,
	speechMarks: SpeechMark[],
	setCurrentWordIndex: React.Dispatch<React.SetStateAction<number>>,
	id: string,
	file_text: boolean,
	raw_file_text: string,
	file_id: string
) => {
	let stop: any = null;
	if (file_id) id = file_id;
	let isBackwards = getGlobalState("backwardsAudio");
	const currentTime = audioRef.current?.currentTime || 0;
	document.querySelectorAll('[id^="word-"]').forEach((word) => {
		word.classList.remove("highlight");
	});

	let newIndex = 0;
	if (isBackwards) {
		for (let i = speechMarks.length - 1; i >= 0; i--) {
			if (speechMarks[i].time <= currentTime * 1000) {
				newIndex = i;
				break;
			}
		}
	} else {
		for (let i = 0; i < speechMarks.length; i++) {
			if (speechMarks[i].time >= currentTime * 1000) {
				newIndex = i;
				break;
			}
		}
	}
	setCurrentWordIndex(newIndex);
	audioRef.current?.removeEventListener("timeupdate", () => {});
	stop = getGlobalState("stopKaraoke");
	stop();
	setTimeout(() => {
		console.log("Real")
		audioRef.current?.addEventListener("timeupdate", () =>
			handleTimeUpdate(
				audioRef,
				isPlaying,
				0,
				setIsPlaying,
				applyKaraokeEffect,
				karaokeTimeoutRef,
				setSpeechMarks,
				setAudioSrc,
				setAllAudioSrc,
				setAllSpeechMarks,
				setData,
				localData,
				speechMarks,
				setCurrentWordIndex,
				id,
				file_text,
				raw_file_text
			)
		);

		audioRef.current?.play();
	}, 100);
};

const splitTextIntoChunks = (text: string): [number, string[]][] => {
	const sentences = text
		.trim()
		.split(/(?<=\.)\s+/)
		.map((sentence) => sentence.trim());
	const result: [number, string[]][] = [];
	let currentChunk: string[] = [];
	let chunkNumber = 1;
	let currentLength = 0;

	for (const sentence of sentences) {
		const sentenceWords = sentence.split(/\s+/);
		const sentenceLength = sentence.length;

		if (sentenceLength > CHUNK_SIZE) {
			// If the current chunk is not empty, push it to the result
			if (currentChunk.length > 0) {
				result.push([chunkNumber, currentChunk]);
				chunkNumber++;
			}

			// Push the long sentence as its own chunk
			result.push([chunkNumber, sentenceWords]);
			chunkNumber++;

			// Reset the current chunk
			currentChunk = [];
			currentLength = 0;
		} else if (currentLength + sentenceLength > CHUNK_SIZE) {
			// If adding this sentence exceeds CHUNK_SIZE, start a new chunk
			result.push([chunkNumber, currentChunk]);
			currentChunk = sentenceWords;
			currentLength = sentenceLength;
			chunkNumber++;
		} else {
			// Add the sentence to the current chunk
			currentChunk.push(...sentenceWords);
			currentLength += sentenceLength + (currentChunk.length > sentenceWords.length ? 1 : 0);
		}
	}

	// Push any remaining content in the current chunk
	if (currentChunk.length > 0) {
		result.push([chunkNumber, currentChunk]);
	}

	return result;
};

const generateTTSForChunk = async (chunk: [number, string[]], token: string, ttsSettings: UserSettings): Promise<TTSChunk> => {
	const chunkText = chunk[1].join(" ");
	const response = await getTTS([], chunkText, ttsSettings);
	return {
		text: chunkText,
		speechMarks: response.speech_marks,
		audioSrc: response.audio,
	};
};

export const generateTTSUtil = async (
	localData: SummaryViewer,
	setSpeechMarks: React.Dispatch<React.SetStateAction<any>>,
	setAudioSrc: React.Dispatch<React.SetStateAction<string>>,
	setData: React.Dispatch<React.SetStateAction<any>>,
	setAllAudioSrc: React.Dispatch<React.SetStateAction<string[]>>,
	setAllSpeechMarks: React.Dispatch<React.SetStateAction<any[]>>,
	id: string,
	setAlreadyGenerating: React.Dispatch<React.SetStateAction<boolean>>,
	alreadyGenerating: boolean,
	file_text: boolean,
	file_id: string,
	raw_file_text?: string
) => {
	// If already generating, return early
	if (alreadyGenerating) {
		console.log("TTS generation already in progress");
		return;
	}

	try {
		setAlreadyGenerating(true);
		let text: SummaryViewer | string;
		if (raw_file_text) text = raw_file_text;
		else text = localData;

		if (file_id) id = file_id;
		const plainText = extractPlainText(text, file_text);
		let chunks = splitTextIntoChunks(plainText);
		setGlobalState("chunks", chunks);
		let allSpeechMarks: any[];
		let allAudioSrc: string[];
		let processedChunks = getGlobalState("processedChunks");
		const token = await auth.currentUser?.getIdToken();
		if (token === undefined) {
			setAlreadyGenerating(false);
			return;
		}

		const ttsSettings = await settingsServices.getUserSettings(auth.currentUser!.uid, token);
		setGlobalState("ttsSettings", ttsSettings);

		const storedData = await getItemById<StoredTTSData>(id, TTS_LOCALSTORAGE_KEY);

		if (storedData.success && storedData.data) {
			const parsedData: StoredTTSData = storedData.data;
			allSpeechMarks = parsedData.allSpeechMarks;
			allAudioSrc = parsedData.allAudioSrc;
			setGlobalState("chunkProcessingIndex", allSpeechMarks.length - 1);
			setGlobalState("ttsCreating", false);
		} else {
			allAudioSrc = [];
			allSpeechMarks = [];
		}
        setAllAudioSrc(allAudioSrc)
        setAllSpeechMarks(allSpeechMarks)
		setBatchGlobalState({
			allAudioSrc: allAudioSrc,
			allSpeechMarks: allSpeechMarks,
		});
		console.log(allSpeechMarks, allAudioSrc);

		if (allSpeechMarks.length > 0 && allAudioSrc.length > 0) {
			for (let i = 0; i < allSpeechMarks.length; i++) {
				processedChunks.push({ text: "", audioSrc: allAudioSrc[i], speechMarks: allSpeechMarks[i] });
			}
			console.log(processedChunks);

			setSpeechMarks(allSpeechMarks[0]);
			setAudioSrc(allAudioSrc[0]);
		} else {
			setGlobalState("ttsCreating", true);
			const firstChunk = await generateTTSForChunk(chunks[0], token, ttsSettings);
			if (firstChunk) {
				setGlobalState("ttsCreating", false);
				processedChunks.push(firstChunk);
				allSpeechMarks.push(firstChunk.speechMarks);
				allAudioSrc.push(firstChunk.audioSrc);
				setSpeechMarks(firstChunk.speechMarks);
				setAudioSrc(firstChunk.audioSrc);
				saveToLocalStorage(id, allSpeechMarks, allAudioSrc, 0);
			}
		}

		setGlobalState("processedChunks", processedChunks);
		const rerenderedData = rerenderWithoutStyling(text, file_text);
		setData(rerenderedData);
	} catch (error) {
		console.error("Error in generateTTSUtil:", error);
	} finally {
		setAlreadyGenerating(false);
	}
};
// Save to localStorage
const saveToLocalStorage = (id: string, allSpeechMarks: any[], allAudioSrc: string[], currentChunkIndexPlaying: number) => {
	console.log(allSpeechMarks);
	const dataToStore: StoredTTSData = {
		allSpeechMarks,
		allAudioSrc,
		processedChunkIndex: currentChunkIndexPlaying,
	};
	// localStorage.setItem(TTS_LOCALSTORAGE_KEY, JSON.stringify(dataToStore));
	console.warn(id);
	addOrUpdateItem(id, dataToStore, TTS_LOCALSTORAGE_KEY);
};
const processNextChunk = (
	token: string,
	ttsSettings: UserSettings | null,
	localData: SummaryViewer | string,
	setData: React.Dispatch<React.SetStateAction<any>>,
	id: string,
	file_text: boolean,
	raw_file_text: string
): Promise<void> => {
	return new Promise(async (resolve, reject) => {
		try {
			let allSpeechMarks = getGlobalState("allSpeechMarks");
			let allAudioSrc = getGlobalState("allAudioSrc");
			let currentChunkIndexGlobal = getGlobalState("currentChunkIndexGlobal");
			let currentlyProcessingIndex = getGlobalState("chunkProcessingIndex");
			let processedChunks = getGlobalState("processedChunks");
			let chunks = getGlobalState("chunks");

			// Check if we already have processed data for this chunk
			if (currentlyProcessingIndex < allSpeechMarks.length && currentlyProcessingIndex < allAudioSrc.length) {
				console.log(`Using existing data for chunk ${currentlyProcessingIndex}`);
				resolve();
				return;
			}

			// If we don't have processed data, generate new TTS
			console.log(`Generating new TTS for chunk ${currentlyProcessingIndex}`, chunks.length);
			if (ttsSettings != null && currentlyProcessingIndex < chunks.length) {
				const chunk = await generateTTSForChunk(chunks[currentlyProcessingIndex], token, ttsSettings);
				if (file_text) localData = raw_file_text;
				const rerenderedData = rerenderWithoutStyling(localData, file_text);

				setData(rerenderedData);
				processedChunks.push({ ...chunk, speechMarks: chunk.speechMarks });
				allSpeechMarks.push(chunk.speechMarks);
				allAudioSrc.push(chunk.audioSrc);
				console.log(allSpeechMarks);
				setBatchGlobalState({
					processedChunks: processedChunks,
					allAudioSrc: allAudioSrc,
					allSpeechMarks: allSpeechMarks,
					hasProcessedChunk: true,
				});
				saveToLocalStorage(id, allSpeechMarks, allAudioSrc, 0);
			}
			setGlobalState("hasProcessedChunk", false);

			resolve();
		} catch (error) {
			reject(error);
		}
	});
};

export const playNextChunk = (
	audioRef: React.RefObject<HTMLAudioElement>,
	isPlaying: boolean,
	currentWordIndex: number,
	setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>,
	applyKaraokeEffect: (startIndexWord: number, startIndexChunk: number) => void,
	karaokeTimeoutRef: React.MutableRefObject<number | null>,
	setSpeechMarks: React.Dispatch<React.SetStateAction<any>>,
	setAudioSrc: React.Dispatch<React.SetStateAction<string>>,
	setAllAudioSrc: React.Dispatch<React.SetStateAction<string[]>>,
	setAllSpeechMarks: React.Dispatch<React.SetStateAction<any[]>>,
	setData: React.Dispatch<React.SetStateAction<any>>,
	localData: SummaryViewer,
	speechMarks: SpeechMark[],
	setCurrentWordIndex: React.Dispatch<React.SetStateAction<number>>,
	id: string,
	file_text: boolean,
	raw_file_text: string
) => {
	let currentChunkIndexPlaying = getGlobalState("currentChunkIndexGlobal");
	let processedChunks = getGlobalState("processedChunks");
	let allSpeechMarks = getGlobalState("allSpeechMarks");
	let stop: any = null;
	audioRef.current?.removeEventListener("timeupdate", () => {});
	stop = getGlobalState("stopKaraoke");
	stop();
	if (audioRef.current) {
		audioRef.current.pause();
		console.log(currentChunkIndexPlaying, processedChunks, currentWordIndex, processedChunks);
		if (currentChunkIndexPlaying < processedChunks.length) {
			const nextChunk = processedChunks[currentChunkIndexPlaying];
			console.log("Playing chunk:", allSpeechMarks, nextChunk, isPlaying);
			audioRef.current.src = nextChunk.audioSrc;
			// setSpeechMarks(nextChunk.speechMarks); // Set all speech marks
			// setAudioSrc(nextChunk.audioSrc);
			setBatchGlobalState({
				currentChunkIndexPlaying: currentChunkIndexPlaying,
				currentChunkIndexGlobal: currentChunkIndexPlaying,
				forwardBackwardsChunkIndex: currentChunkIndexPlaying,
				playingWordsIndex: 0,
				playingNextChunk: true,
				// hasProcessedChunk: false,
			});

			playPauseUtil(
				audioRef,
				true,
				0,
				currentChunkIndexPlaying,
				setIsPlaying,
				applyKaraokeEffect,
				karaokeTimeoutRef,
				setSpeechMarks,
				setAudioSrc,
				setAllAudioSrc,
				setAllSpeechMarks,
				setData,
				localData,
				nextChunk.speechMarks,
				setCurrentWordIndex,
				id,
				file_text,
				raw_file_text
			);
		} else {
			stop = getGlobalState("stopKaraoke");
			stop();
			setIsPlaying(false);
			document.querySelectorAll('[id^="word-"]').forEach((word) => {
				word.classList.remove("highlight");
			});
			console.log("No more chunks to play.");
		}
	}
};

interface DataItem<T> {
	id: string;
	data: T;
}

// Database configuration
export const DB_NAME = "AppDatabase";
export const STORAGE_KEYS = {
	TTS: "tts-data",
	EDITOR: "editor-data",
} as const;

// Type for database operations result
interface DBResult<T> {
	success: boolean;
	data?: T;
	error?: string;
}

// Initialize the database with better error handling
// Initialize the database with better error handling
// Initialize the database with version management
async function initDB(storage_key: string): Promise<IDBDatabase> {
    console.log(`Initializing DB for store: ${storage_key}`);

    return new Promise((resolve, reject) => {
        // First, try to open without version to get current version
        const checkRequest = indexedDB.open(DB_NAME);
        
        checkRequest.onsuccess = () => {
            const currentVersion = checkRequest.result.version;
            checkRequest.result.close();
            
            // Open with correct version
            const request = indexedDB.open(DB_NAME, currentVersion);

            request.onerror = (event) => {
                console.error(`DB error for store ${storage_key}:`, request.error);
                reject(request.error);
            };

            request.onsuccess = (event) => {
                const db = request.result;
                console.log(`DB opened successfully. Version: ${db.version}, Stores:`, Array.from(db.objectStoreNames));

                if (!db.objectStoreNames.contains(storage_key)) {
                    db.close();
                    const newVersion = db.version + 1;
                    console.log(`Upgrading DB to version ${newVersion} for store ${storage_key}`);

                    const newRequest = indexedDB.open(DB_NAME, newVersion);

                    newRequest.onupgradeneeded = (e) => {
                        const newDb = newRequest.result;
                        console.log(`Creating store: ${storage_key}`);
                        if (!newDb.objectStoreNames.contains(storage_key)) {
                            newDb.createObjectStore(storage_key, { keyPath: "id" });
                        }
                    };

                    newRequest.onsuccess = () => {
                        console.log(`Store ${storage_key} created successfully`);
                        resolve(newRequest.result);
                    };

                    newRequest.onerror = () => {
                        console.error(`Failed to create store ${storage_key}:`, newRequest.error);
                        reject(newRequest.error);
                    };
                } else {
                    resolve(db);
                }
            };

            request.onupgradeneeded = (event) => {
                const db = request.result;
                console.log(`DB upgrade needed. Creating store: ${storage_key}`);
                if (!db.objectStoreNames.contains(storage_key)) {
                    db.createObjectStore(storage_key, { keyPath: "id" });
                }
            };
        };

        checkRequest.onerror = () => {
            console.error("Failed to check database version:", checkRequest.error);
            reject(checkRequest.error);
        };
    });
}

// Add or update an item with better error handling
export async function addOrUpdateItem<T>(id: string | undefined, data: T, storage_key: string): Promise<DBResult<DataItem<T>>> {
	if (!id) {
		console.error("No ID provided for addOrUpdateItem");
		return { success: false, error: "No ID provided" };
	}

	try {
		const db = await initDB(storage_key);
		const item: DataItem<T> = { id, data };

		return new Promise((resolve) => {
			const transaction = db.transaction(storage_key, "readwrite");
			const store = transaction.objectStore(storage_key);

			console.log(`Adding/Updating item with ID ${id} in store ${storage_key}`);
			const request = store.put(item);

			request.onsuccess = () => {
				console.log(`Successfully saved item with ID ${id}`);
				resolve({ success: true, data: item });
			};

			request.onerror = () => {
				console.error(`Failed to save item with ID ${id}:`, request.error);
				resolve({ success: false, error: request.error?.message });
			};
		});
	} catch (error) {
		console.error(`Error in addOrUpdateItem for ID ${id}:`, error);
		return { success: false, error: "Database operation failed" };
	}
}

// Get an item by ID with better error handling
export async function getItemById<T>(id: string | undefined, storage_key: string): Promise<DBResult<T>> {
	if (!id) {
		console.error("No ID provided for getItemById");
		return { success: false, error: "No ID provided" };
	}

	try {
		const db = await initDB(storage_key);

		return new Promise((resolve) => {
			const transaction = db.transaction(storage_key, "readonly");
			const store = transaction.objectStore(storage_key);

			console.log(`Fetching item with ID ${id} from store ${storage_key}`);
			const request = store.get(id);

			request.onsuccess = () => {
				const result = request.result;
				if (result && "data" in result) {
					console.log(`Successfully retrieved item with ID ${id}`);
					resolve({ success: true, data: result.data as T });
				} else {
					console.log(`No data found for ID ${id}`);
					resolve({ success: false, error: "Item not found" });
				}
			};

			request.onerror = () => {
				console.error(`Failed to fetch item with ID ${id}:`, request.error);
				resolve({ success: false, error: request.error?.message });
			};
		});
	} catch (error) {
		console.error(`Error in getItemById for ID ${id}:`, error);
		return { success: false, error: "Database operation failed" };
	}
}
export async function deleteItem(id: string | undefined, storage_key: string): Promise<DBResult<void>> {
    if (!id) {
        console.error("No ID provided for deleteItem");
        return { success: false, error: "No ID provided" };
    }

    try {
        const db = await initDB(storage_key);

        return new Promise((resolve) => {
            const transaction = db.transaction(storage_key, "readwrite");
            const store = transaction.objectStore(storage_key);

            console.log(`Deleting item with ID ${id} from store ${storage_key}`);
            const request = store.delete(id);

            request.onsuccess = () => {
                console.log(`Successfully deleted item with ID ${id}`);
                resolve({ success: true });
            };

            request.onerror = () => {
                console.error(`Failed to delete item with ID ${id}:`, request.error);
                resolve({ success: false, error: request.error?.message });
            };
        });
    } catch (error) {
        console.error(`Error in deleteItem for ID ${id}:`, error);
        return { success: false, error: "Database operation failed" };
    }
}

